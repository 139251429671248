import React, { Component ,CSSProperties } from "react";
import { connect } from "react-redux";
import { addToCart, deleteItem } from "./actions/cartActions";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditItemForm from "./forms/EditItemForm";
import { Button, FormThemeProvider } from "react-form-component";
import ReactReadMoreReadLess from "react-read-more-read-less";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { hostUrl } from "../App";

import { Sliderify } from "react-sliderify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
// import { useState, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      open: false,
      editData: [],
      user_define: {},
      checkout: [],
      cart: [],
      placed_orders:[],
      deleteOpen :false,
      deleteId : null,
      cart_checked: null,
      orders : [],
      global_order : [],
      global_cart :[],
      all_empty :[],
      loading : true,
      cond_orders : [],
      cond_cart:[]
    };
  }
  notify = () => toast.success("Item Added to the Cart!");
  
  // handleChildToProp = () => {
    
  // };
  componentDidMount = async () => {
    // console.log("local data :", JSON.parse(localStorage.getItem("Checkout")));
    //fetching all assets through api call---------------start----------------------------------------------
    // console.log("home ",this.props.user._id , this.props.user.token);
    
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        
      },
    };
    axios
      .request(config)
      .then((response) => {
        console.log("Assets ",response.data.res);
        this.setState({ data: response.data.res });
        // localStorage.setItem("Assets", JSON.stringify(response.data.res));
      })
      .catch((error) => {
        console.log("err :", error);
      });

    //-------------end-----------------------

    let cart_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/shopping/cart/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
      },
    };

    axios
      .request(cart_config)
      .then((response) => {
        console.log("cart : ",response.data.cart);   

        this.props.onSelectTotal(response.data.cart.length); 

        this.setState({
          cart: response.data.cart,
          cond_cart : response.data.cart.map((obj)=>obj.assetId)
        });
      })
      .catch((error) => {
        console.log("error :", error);
      });

    //----------After Checkouts ----------------

    // const axios = require('axios');

    let checkout_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/checkout/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM'
      },
    };

    axios
      .request(checkout_config)
      .then((response) => {
        console.log("orders :",response.data.orders);
        const res = response.data.orders.filter((obj)=>obj.Status !== "Cancel")
        this.setState({
          checkout:res ,
        });
      })
      .catch((error) => {
        console.log(error);
      });

   
     

    //-------end------------------------------

    //Placed orders
    let order_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/checkout/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };

    axios
      .request(order_config)
      .then((response) => {
        console.log("Placed orders :",response.data.orders.filter((item)=>item.Status === "Approve"));
        const approved_orders = response.data.orders.filter((item)=>item.Status === "Approve")
        this.setState({
          placed_orders: approved_orders.map((item)=>item.AssetId),
        });        
      })
      .catch((error) => {
        console.log(error);
      });

      let enable_cart_config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets/enableCart`,
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,        
        },
      };
  
      axios
        .request(enable_cart_config)
        .then((response) => {
          console.log("enable cart :", response.data.enable_cart[0]);
          this.setState({ cart_checked: response.data.enable_cart[0] });
        })
        .catch((error) => {
          console.log(error);
        });
    this.setState({
      user_define: this.props.user,
      // checkout: JSON.parse(localStorage.getItem("Checkout")),
    });

    let orders_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/checkout/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };

    axios
      .request(orders_config)
      .then((response) => {
        console.log(response.data.orders);
        this.setState({
          orders : response.data.orders,
        });        
      })
      .catch((error) => {
        console.log(error);
      });

      let ordes_config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets/orders`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.token}`,
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
        },
      };
  
      axios
        .request(ordes_config)
        .then((response) => {
          console.log("my orders 244 :", response.data.orders.filter((item)=>item.Status === "Open").map((obj)=>obj.AssetId));
          this.setState({
            cond_orders : response.data.orders.filter((item)=>item.Status === "Open").map((obj)=>obj.AssetId)
          })
       
        })
        .catch((error) => {
          console.log(error);
        });


     setTimeout(() => {
      const Approve_order = this.state.checkout.filter((obj)=>obj.Status === "Approve").map((item)=>item.AssetId)
      const All_approves = this.state.data.filter((obj)=>obj.Display && !Approve_order.includes(obj._id)   )
        console.log('This will run after 1 second!',All_approves)
        this.setState({
          all_empty : All_approves,
          loading : false
        })

      }, 1000);


  };

  modelOpen = (item) => {
    this.setState({ open: true });

    this.setState({
      editData: item,
    });
  };
  modelClose = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log("Assets ",response.data.res);
        this.setState({ data: response.data.res });
        // localStorage.setItem("Assets", JSON.stringify(response.data.res));
      })
      .catch((error) => {
        console.log("err :", error);
      });

    this.setState({ open: false });
  };

  handleClick = (id) => {
    this.setState({
      
      loading : true
    })
    const selAsset = this.state.data.find((obj) => obj._id === id);

    const validating_item = this.state.cart.filter(
      (obj) => obj.Category === selAsset.Category
    );

    const orderVal = this.state.checkout.filter(
      (obj) => obj.Category === selAsset.Category && (obj.Status === "Open" ||  obj.Status === "Approve")
    );
    if (!validating_item.length && !orderVal.length && selAsset.Quantity_Available !==0 ) {
      // console.log("validating_item :",validating_item);
      delete selAsset.createdAt;
      delete selAsset._id;
      selAsset.userId = this.state.user_define._id
      selAsset.assetId = id 
      selAsset.checkoutStatus = false
      selAsset.createdAt = new Date()
      console.log("sel obj :",selAsset);
      // let addedItem = { _id: id, userId: this.state.user_define._id };
      // console.log("add cart :",addedItem);

      let data = JSON.stringify(selAsset);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets/shopping/cart`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.token}`,
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data.asset);
          // addToCart(id)
         
          this.setState({
            data: response.data.asset,
            cart: response.data.cart.filter((item)=> item.checkoutStatus === false),
            cond_cart: response.data.cart.filter((item)=> item.checkoutStatus === false && item.userId == this.props.user._id).map((obj)=>obj.assetId)
          });
         console.log("response.data.cart 329:",response.data.cart.filter((item)=> item.checkoutStatus === false && item.userId == this.props.user._id).map((obj)=>obj.assetId));
            this.notify()
            this.props.onSelectTotal(response.data.cart.filter((item)=> item.checkoutStatus === false).length); 
          
        })
        .catch((error) => {
          console.log(error);
        });


        let checkout_config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${hostUrl}/api/v1/assets/orders`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.user.token}`,
            // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
          },
        };
    
        axios
          .request(checkout_config)
          .then((response) => {
            console.log("my orders 349 :", response.data.orders.filter((item)=>item.Status === "Open").map((obj)=>obj.AssetId));
            this.setState({
              cond_orders : response.data.orders.filter((item)=>item.Status === "Open").map((obj)=>obj.AssetId)
            })
         
          })
          .catch((error) => {
            console.log(error);
          });

        // this.componentDidMount()
        
        // let global_config = {
        //   method: "get",
        //   maxBodyLength: Infinity,
        //   url: `${hostUrl}/api/v1/assets/globalOrder`,
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${this.props.user.token}`,
        //     // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM'
        //   },
        // };
    
        // axios
        //   .request(global_config)
        //   .then((response) => {
        //     console.log("orders and carts :",response.data.orders.filter((obj)=>obj.Status === "Open").map((item)=>item.AssetId) ,response.data.carts.filter((obj)=>obj.checkoutStatus === false).map((item)=>item.assetId));
        //     // const res = response.data.orders.filter((obj)=>obj.Status !== "Cancel")
        //     this.setState({
        //       global_cart : response.data.carts.filter((obj)=>obj.checkoutStatus === false).map((item)=>item.assetId),
        //       // response.data.carts.map((item)=>item.assetId) ,
        //       global_order : response.data.orders.filter((obj)=>obj.Status === "Open").map((item)=>item.AssetId) ,
        //     });
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        //   setTimeout(() => {
        //     const Approve_order = this.state.checkout.filter((obj)=>obj.Status == "Approve").map((item)=>item.AssetId)
        //     const All_approves = this.state.data.filter((obj)=>obj.Display && !Approve_order.includes(obj._id)   )
        //       console.log('This will run after 1 second!',All_approves)
        //       this.setState({
        //         all_empty : All_approves,
        //         loading : false
        //       })
      
        //     }, 1000);
    } else {
      alert("Exceeded item count limit");
    }
    // this.props.addToCart(this.state.cart);
  };
  handleClickDelete = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.deleted);
        // const idToRemove = 5;

        const filteredPeople = this.state.data.filter(
          (item) => item._id !== id
        );

        console.log("delete :", filteredPeople);
        this.setState({ data: filteredPeople });

        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleCallback = (childData) => {
    // console.log("chaild",childData);
    // this.setState({name: childData})
    // console.log("childData :",childData);
    // this.setState({ data :childData});
    
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };
    axios
      .request(config)
      .then((response) => {        
        console.log("editing image :",response.data.res);
        this.setState({ data: response.data.res ,open: false  });    
       
      })
      .catch((error) => {
        console.log("err :", error);
      });
     
      
  };

  onOpenDeleteModal = (id) => {
    

    this.setState({ deleteOpen: true  , deleteId :id })
    
  };
  onDeleteAsset = () => {
    // this.setState({ deleteOpen: true });
    console.log("conform id :",this.state.deleteId);
    this.handleClickDelete(this.state.deleteId)
    this.setState({ deleteOpen: false   })
  };
  onCloseDeleteModal = () => {
    this.setState({ deleteOpen: false });
  };

  render() {
    // this.props.addToCart(this.state.cart);
    localStorage.setItem("Total",this.state.cart.length)
    let itemList = this.state.data.map((item) => {    
        if (  (this.state.user_define.role === "user" && !this.state.placed_orders.includes(item._id) && item.Display &&
         (item.Quantity_Available !== 0  || this.state.cond_orders.includes(item._id) || this.state.cond_cart.includes(item._id)
          // (this.state.global_cart.includes(item._id) || this.state.global_order.includes(item._id)) 
          )
         )  || 
        this.state.user_define.role === "admin"
        ) {  
      return (
        <div class="card sticky-action  hoverable" key={item._id}>
        <div class="card-image waves-effect waves-block waves-light">
          <div className="card-image" >      

            <Sliderify showNavButtons={true} showSpot={false}>
           
              {item.image.map((fadeImage, index) => (               
                <div key={index} > 
                          
                  <img style={{ width: "100%" , height: "200px" }} src={ fadeImage.url.includes(hostUrl) ? fadeImage.url :hostUrl+"/"+ fadeImage.url} alt=""/>
                </div>
              ))}
            </Sliderify>
          </div>
        </div>
            <div
              class="card-content"
              style={{ paddingTop: "40px", fontSize: "20px" }}
            >
              <span class="card-title activator yellow-text text-darken-4">
                <b>
                  {item.Name.length <= 25
                    ? item.Name
                    : item.Name.slice(0, 25) + "..."}
                </b>
                <i class="material-icons grey-text text-darken-4 right">
                  more_vert
                </i>
              </span>
            </div>

            <div class="card-content">
              <p>
                <b>Price: <span class="WebRupee">&#x20B9;</span> {item.Price}</b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <b>
                  {" "}
                  Quantity :{" "}
                  {this.state.user_define.role === "user"
                    ? this.state.cart.findIndex((x) => x.assetId === item._id) ===
                      -1
                      ? this.state.checkout.filter(
                          (obj) =>
                          
                            obj.AssetId === item._id &&
                            obj.userId === this.state.user_define._id
                        ).length
                        ? 0
                        : item.Quantity_Available ?item.Quantity_Available - item.Quantity_Available + 1 : item.Quantity_Available
                      : 0
                    : item.Quantity_Available}
                </b>
              </p>
              {/* <p>{ item.Status === "In Stock" ?<span className="statusCol"> <span class="dot"></span> {item.Status}</span> :<span className="statusCol_1"><span class="dot1"></span> {item.Status}</span>}</p> */}
              <p>
               {/* { console.log(this.state.cart.map((x) => x.assetId ) )} */}
                {item.Quantity_Available === 0 ?(
                  <span className="statusCol_1">
                    <span class="dot1"></span> Out Of Stock
                  </span>
                ) : this.state.cart.findIndex((x) => x.assetId === item._id) === -1 ? (
                  this.state.checkout.filter(
                    (obj) =>
                      obj.AssetId === item._id &&
                      obj.userId === this.state.user_define._id
                  ).length ? (
                    <span className="statusCol_1">
                    <span class="dot1"></span> Out Of Stock
                  </span>
                  ) : (
                    <span className="statusCol">
                      {" "}
                      <span class="dot"></span> In Stock
                    </span>
                  )
                ) : (
                  <span className="statusCol_1">
                    <span class="dot1"></span> Out Of Stock
                  </span>
                )}
              </p>
            </div>
            <div class="card-reveal">
              <span class="card-title yellow-text text-darken-4">
                <b>{item.Name}</b>
                <i class="material-icons right grey-text text-darken-4">
                  close
                </i>
              </span>
              <p><b>Serial No : </b>{item.Serial_No}</p>
              <p>
              <b>Description : </b>
                <ReactReadMoreReadLess
                  charLimit={200}
                  readMoreText={"Read more ▼"}
                  readLessText={"...Read less ▲"}
                  readMoreStyle={{whiteSpace: "nowrap", textDecoration: "none",fontWeight: "bold"}}
                  readLessStyle={{whiteSpace: "nowrap", textDecoration: "none",fontWeight: "bold"}}
                >
                  {item.Description}
                </ReactReadMoreReadLess>
              </p>              
            </div>

            {/* {this.state.user_define.role === "user" ? null : (<> */}
            {this.state.user_define.role === "user" ? (
              // <div class="card-action ">
              <span
                to="/"
                className="btn-floating halfway-fab waves-effect waves-light red"
                onClick={() => {
                  console.log("checked :",this.state.cart_checked);
                  if(this.state.cart_checked.Enable){
                    this.handleClick(item._id);
                  }else{
                    alert("Checkout is currently disabled by the Admin")
                  }
                  
                  
                }}
                title="Add to cart"
              >
                <i className="material-icons">add</i>
              </span>
            ) : (
              // </div>
              <div class="card-action ">
                <>
                  <span
                    to="/"
                    title="Delete"
                    className="btn-floating  waves-effect waves-light blue left"
                    onClick={() => {
                      // this.handleClickDelete(item._id);
                      // this.submit(item._id)
                      this.onOpenDeleteModal(item._id)
                    }}
                  >
                    <i className="material-icons">delete</i>
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    to="/"
                    title="Edit"
                    className="btn-floating  waves-effect waves-light orange "
                    onClick={() => this.modelOpen(item)}
                  >
                    <i className="material-icons">edit</i>
                  </span>
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  <span
                    to="/"
                    className="btn-floating waves-effect waves-light red float-right right"
                    onClick={() => {
                      console.log("checked :",this.state.cart_checked);
                  if(this.state.cart_checked.Enable){
                    this.handleClick(item._id);
                  }else{
                    alert("Checkout is currently disabled by the Admin")
                  }
                      
                    }}
                    title="Add to cart"
                  >
                    <i className="material-icons">add</i>
                  </span>
                </>
              </div>
            )}
          </div>
        );
      }
    });

    return (
      <div className="container home pageAlignment">
       { this.state.data.length  ? this.state.all_empty.length ? <>
        <Toaster />
        <div className="box">
          <Modal open={this.state.open} onClose={this.modelClose} center>
            <FormThemeProvider>
              <EditItemForm
                item={this.state.editData}
                parentCallback={this.handleCallback}
              />
            </FormThemeProvider>
          </Modal>        
          
          {itemList}
        </div>
        </>
        :this.state.loading ?<div style={{textAlign : "center" , paddingTop : "200px"}}> <ClipLoader
        color="#ffffff"
        loading={true}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> </div> :<>
        <div style={{textAlign : "center" , paddingTop : "200px"}}><h3>No Data Found...</h3></div> 
        </>
       
        : this.state.loading ? <div style={{textAlign : "center" , paddingTop : "200px"}}><ClipLoader
        color="#ffffff"
        loading={true}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      /></div> :<>
        <div style={{textAlign : "center" , paddingTop : "200px"}}><h3>No Data Found...</h3></div> 
        </> }

        <Modal open={this.state.deleteOpen} onClose={this.onCloseDeleteModal}>
          <div style={{width : "500px"}}>
          <h5>Confirm to Delete</h5>
          <p>Are you sure you want to Delete the Item?</p>
          <div class="right-align">
          <button class="waves-effect waves-light btn" onClick={this.onDeleteAsset}>Ok</button>&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="btn waves-effect waves-light red" onClick={this.onCloseDeleteModal}>Cancel</button>
          </div>          
          </div>          
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state);
  return {
    // items: state.items,
    user: state.user,
    // addedItems: state.addedItems,
    // orders: state.order,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    deleteItem: (id) => {
      dispatch(deleteItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
