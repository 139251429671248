import React, { Component } from "react";
import { connect } from "react-redux";
// import { addShipping } from './actions/cartActions'
import { hostUrl } from "../App";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ContactForm from "./forms/ContactForm";
import axios from "axios";
import { FormThemeProvider } from "react-form-component";
class Recipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      open: false,
      total: 0,
    };
  }
  modelClose = (id) => {
    this.setState({ open: false });
    window.location.reload();
  };
  AssetCheckout = (event) => {

    // onTrigger = (event) => {
      // Call the parent callback function
      
  // }
    // this.setState({ open: true });
    const body = this.props.user;
      // body["userId"] = this.props.user._id;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${this.props.user.token}`
      );
      console.log("ORDER_ITEM :", body);
      var raw = JSON.stringify(body);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${hostUrl}/api/v1/assets/checkout`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log("checkout res :",result);
          // Callback function---start-----
          this.props.parentCallback([]);
          event.preventDefault();
          // Callback function---end-----
          if (JSON.parse(result).status === 401) {
            this.setState({
              status_error: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status_error: true,
          });
          console.log("error", error);
        });

  };
  handleCallback = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
console.log("Recept :",this.props.TotalAmt);

    // let cart_config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: `${hostUrl}/api/v1/assets/shopping/cart/${this.props.user._id}`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization:
    //     `Bearer ${this.props.user.token}`
    //       // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
    //   },
    // };

    // axios
    //   .request(cart_config)
    //   .then((response) => {        
    //     const sumTotal = (arr) =>
    //       arr.reduce((sum, { Price }) => sum + Price, 0);
    //     // console.log(sumTotal(res));
    //     this.setState({
    //       total: sumTotal(response.data.cart),
    //     });
    //   })
    //   .catch((error) => {
    //     console.log("error :", error);
    //   });
  }

  render() {
    console.log("Recept :",this.props.TotalAmt);
    return (
      <div className="container">
        <div className="collection">
          <li className="collection-item">
            <b>Total: ₹ {this.props.TotalAmt} 
              {/* {this.state.total}  */}
              </b>
          </li>
        </div>
        <div className="checkout">
          <button
            className="waves-effect waves-light btn"
            onClick={this.AssetCheckout}
            disabled={this.props.TotalAmt ? false : true}
           
          >
            Checkout
          </button>
          {/* <Modal open={this.state.open} onClose={this.modelClose} center>
            <div class="col m12 m7">
             
              <div class="">
                <div class="">
                  <div class="m8">
                    <FormThemeProvider>
                      <ContactForm parentCallback={this.handleCallback} />
                    </FormThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    // total: state.total,
  };
};

// const mapDispatchToProps = (dispatch)=>{
//     return{
//         addShipping: ()=>{dispatch({type: 'ADD_SHIPPING'})},
//         substractShipping: ()=>{dispatch({type: 'SUB_SHIPPING'})}
//     }
// }

// export default connect(mapStateToProps,mapDispatchToProps)(Recipe)
export default connect(mapStateToProps)(Recipe);
