
import React, { Component } from "react";

import BasicExampleForm from "./forms/BasicExampleForm";
import { FormThemeProvider } from "react-form-component";
export default class Admin extends Component {
  render() {
    return (
      <div className="container adminPage"> 
        <FormThemeProvider>
          <BasicExampleForm />
        </FormThemeProvider>
      </div>
    );
  }
}
