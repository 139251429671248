import React, { Component } from "react";
import { connect } from "react-redux";
import Form, {
  Input,
  Select,
  FormButton,
  Row,
  Switch,
  TextArea,
  Fieldgroup,
  GalleryUpload,
} from "react-form-component";
import axios from "axios";
import { addItem } from "../actions/cartActions";
import { Modal } from "react-responsive-modal";
import toast, { Toaster } from "react-hot-toast";
import { hostUrl } from "../../App";
// import { NoFlashRounded } from "@mui/icons-material";
import { FormThemeProvider } from "react-form-component";
// import { Category } from "../Category";
// import Category from "../Category";
import Category from "../Category";
// import Form, { Input, Row, FormButton, TextArea } from "react-form-component";
// import Form, {
// import { useSnackbar } from 'notistack'
// } from "react-form-component";
class BasicExampleForm extends Component {
  constructor() {
    super();
    this.state = {
      image_path: null,
      open: false,
      serialValidate: false,
      Assets: [],
      Quantity: 2,
      quantityEdit:null,
      Categories: [
        {
          label: "Please select",
          value: "Please select",
        },
      ],
    };
  }
  errorNotify = (err) => toast.error(`${err}`);

  componentDidMount = () => {
    console.log("total items :", this.props.items);
    // const axios = require('axios');
    let configAsset = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };
    axios
      .request(configAsset)
      .then((response) => {
        // console.log("Assets ",response.data.res);
        this.setState({ Assets: response.data.res });
        // localStorage.setItem("Assets", JSON.stringify(response.data.res));
      })
      .catch((error) => {
        console.log("err :", error);
      });

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/category`,
      headers: {
        Authorization: `Bearer ${this.props.user.token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("category :", response.data.category);
        this.setState({
          Categories: [...this.state.Categories, ...response.data.category],
        });
      })
      .catch((error) => {
        console.log("error :", error);
      });
  };
  notify = () => toast.success("Successfully created!");
  formSubmit = (data) => {
    // console.log(
    //   "file length :",
    //   Object.values(data.example),
    //   Object.values(this.state.image_path)
    // );
    const serialVal = this.state.Assets.filter(
      (item) => item.Serial_No === data.Serial_No
    );
    if (serialVal.length) {
      // console.log("exist");
      this.setState({ serialValidate: true });
    } else {
      var formData = new FormData();
      // formData.append("image", Object.values(this.state.image_path));
      formData.append("Description", data.Description);
      // formData.append("Item_Code", data.Item_Code);
      formData.append("Quantity_Available", data.Quantity_Available);
      formData.append("Manufacturer_Year", data.Manufacturer_Year);
      formData.append("Display", data.Display);
      formData.append("Category", data.Category);
      // formData.append("Status", data.Status);
      formData.append("Name", data.Name);
      formData.append("Price", data.Price);
      formData.append("Serial_No", data.Serial_No);

      //********* HERE IS THE CHANGE ***********
      for (let i = 0; i < this.state.image_path.length; i++) {
        formData.append("files", this.state.image_path[i]);
      }

      console.log(formData.get("files"));

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets`,
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
        data: formData,
      };

      axios
        .request(config)
        .then((response) => {
          this.notify();
          console.log(JSON.stringify(response.data));
          
              setTimeout(() => {
                window.location.reload();
              }, 1500);
        })
        .catch((error) => {
          console.log(error);
        });

      // data.image = this.state.image_path
      console.log("form data :", formData);
      // this.props.addItem(formData);
      this.setState({ serialValidate: false });
    }
  };
  handleChange = (e) => {
    console.log("e.target.files :", e.target.files);
    this.setState({ image_path:Object.values(e.target.files)  });
    // this.setState({ image_path: e.target.files });
  };
  handleCreate = () => {
    console.log("create a new category");
    this.setState({ open: true });
  };
  modelClose = () => {
    this.setState({ open: false });
  };
 
  handleCallback = (childData) => {
    // Update the name in the component's state
    console.log(childData);
    this.setState({ Categories: childData });
  };
  removeSelectedImage = (index) => {
    // delete this.state.image_path[Ind];
   const deleteImg =  this.state.image_path
    // delete this.state.image_path["keyname"];
    // deleteImg.slice(index,1)
    this.setState({
      image_path : deleteImg.filter((val,ind)=>ind !== index)
    })
    console.log("Image delete :",index,   deleteImg.filter((val,ind)=>ind !== index) );
    
  };
  render() {
    // const stl = {textAlign: 'center', fontWeight : "20px"};
    return (
      <div>
        <Toaster />

        <Form
          fields={[
            "Name",
            // "Item_Code",
            "Manufacturer_Year",
            "Price",
            "Quantity_Available",
            "Display",
            // "Status",
            "Description",
            "image",
            "Serial_No",
            "Category",
          ]}
          mandatory={[
            "Name",
            "Manufacturer_Year",
            "Price",
            "Quantity_Available",
            "Serial_No",
            "Description",
            "image",
            "Category",
          ]}
          onChange={(e)=>this.setState({quantityEdit : e.Category})}
        >
          <Row>
            <Input name="Name" label="Name*" required />
            <Input
              name="Serial_No"
              // type="number"
              label="Serial No*"
              help={
                this.state.serialValidate ? (
                  <span onClick={this.handleCreate} style={{ color: "red" }}>
                    {" "}
                    The specified serial no. already exist
                  </span>
                ) : (
                  ""
                )
              }
            />
          </Row>
          <Row>
            <Input name="Price" type="number" label="Price*" />

            <Input
              label="Manufacturer Year*"
              name="Manufacturer_Year"
              type="date"
            />
          </Row>

          <Row>
            <Input
              name="Quantity_Available"
              type="number"
              label="Quantity Available*"
              initialValue={1}
              disabled={this.state.quantityEdit === null || this.state.quantityEdit === undefined ? true:
                (this.state.quantityEdit.toLowerCase() === "keyboard" ||
                this.state.quantityEdit.toLowerCase() === "keyboards" ||
                 this.state.quantityEdit.toLowerCase() === "mouse" ||
                 this.state.quantityEdit.toLowerCase() === "mouses" ) ? false : true
                  }
            />
            <Select
              className=""
              // help="Help text"
              placeholder=""
              label="Category*"
              name="Category"
              options={this.state.Categories}
              prefix=""
              suffix=""
              help={
                <span
                  onClick={this.handleCreate}
                  style={{ color: "blue", textDecoration: "underline " }}
                >
                  Manage Categories
                </span>
              }
            />
          </Row>
          <Row>
            <TextArea
              className=""
              help="Minimum 5 characters"
              initialValue=""
              label="Description*"
              min={5}
              name="Description"
              placeholder="Placeholder text"
              prefix=""
              rows={2}
              suffix=""
            />
            <Switch
              className=""
              help=""
              label="Display on Live page?"
              name="Display"
              initialValue={true}
              text="Do you want to display item in home page"
            />
          </Row>
          <Row>
            <Input
              name="image"
              multiple
              // filename={file}
              onChange={this.handleChange}
              type="file"
              accept="image/*"
              // multiple=""
            />
          </Row>
          <Row>
            {this.state.image_path !== null
              ? this.state.image_path.map((Ind ,index) => {
                  return (
                    <>
                      {/* <img src={ URL.createObjectURL(this.state.image_path[Ind])}  alt=""/> */}
                      <div style={styles.preview} key={Ind}>
                        <img
                          src={URL.createObjectURL(Ind)}
                          style={styles.image}
                          alt="Thumb"
                        />
                        <button
                          onClick={() => this.removeSelectedImage(index)}
                          style={styles.delete}
                        >
                          Delete                        </button>
                      </div>
                    </>
                  );
                })
              : ""}
          </Row>

          <FormButton
            onClick={(fields) => {
              this.formSubmit(fields);
              
            }}
          >
            Add Inventory
          </FormButton>
          <span> </span>
          <button
            onClick={() =>
              // (window.location.href = "http://localhost:3000/")
              (window.location.href = "https://assetportal.mgdevops.com/")
              
            }
            class="btn waves-effect waves-light right"
          >
            <i class="material-icons left">home</i> Go to Assets
          </button>
        </Form>

        <Modal
          open={this.state.open}
          onClose={this.modelClose}
          center
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal',
          }}
          
        >
          <Category
            category={this.state.Categories.filter((item) => "_id" in item)}
            Token={this.props.user.token}
            parentCallback={this.handleCallback}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // items: state.items,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => {
      dispatch(addItem(item));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicExampleForm);
const styles = {
  container: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // paddingTop: 50,
  },
  preview: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: 150, maxHeight: 100 },
  delete: {
    cursor: "pointer",
    padding: 10,
    background: "red",
    color: "white",
    border: "none",
     justifyContent: "center",
    alignItems: "center",
  },
};

