import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  removeItem,
  addQuantity,
  subtractQuantity,
} from "./actions/cartActions";
import Recipe from "./Recipe";
import axios from "axios";
import { hostUrl } from "../App";
import { Sliderify } from "react-sliderify";
class Cart extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      cart: [],
      total: 0,
      user: {},
      checkoutSuccess: false,
    };
  }
  componentDidMount = () => {
    console.log("carts :");

    let cart_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/shopping/cart/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };

    axios
      .request(cart_config)
      .then((response) => {
        console.log(response.data.cart);
        const sumTotal = (arr) =>
          arr.reduce((sum, { Price }) => sum + Price, 0);

        // console.log("count :",sumTotal(response.data.cart));
        this.setState({
          cart: response.data.cart,
          total: sumTotal(response.data.cart),
        });
        //   localStorage.setItem("Total", sumTotal(res));
      })
      .catch((error) => {
        console.log("error :", error);
      });
  };
  //to remove the item completely
  handleRemove = (id) => {
    let data = JSON.stringify({
      _id: id,
    });
    console.log("user :", this.props.user._id);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/shopping/cart/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.cart);
        // localStorage.setItem("Assets", JSON.stringify(response.data.asset));
        const cartRes = response.data.cart.filter(
          (item) => item.checkoutStatus === false
        );
        const sumTotal = (arr) =>
          arr.reduce((sum, { Price }) => sum + Price, 0);

        // window.location.reload(true);

        this.props.onSelectTotal(cartRes.length);
        this.setState({
          // data : response.data.asset ,
          cart: cartRes,
          total: sumTotal(cartRes),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCallback = (childData) => {
    const sumTotal = (arr) => arr.reduce((sum, { Price }) => sum + Price, 0);

    // window.location.reload(true);

    this.props.onSelectTotal(childData.length);
    this.setState({
      // data : response.data.asset ,
      cart: childData,
      total: sumTotal(childData),
      checkoutSuccess: true,
    });

    // console.log("callback function :",childData);
  };

  render() {
    let addedItems = this.state.cart.length ? (
      this.state.cart.map((item, ind) => {
        return (
          <div class="col s12 m8 offset-m2 l6 offset-l3" key={ind}>
            <div class="card-panel grey lighten-5 z-depth-1">
              <div class="row valign-wrapper">
                <div class="col s3">
                  <Sliderify showNavButtons={true} showSpot={false}>
                    {item.image.map((fadeImage, index) => (
                      <>
                        <img
                          src={
                            fadeImage.url.includes(hostUrl)
                              ? fadeImage.url
                              : hostUrl + "/" + fadeImage.url
                          }
                          alt="slide images"
                          style={{ height: "200px" }}
                        />
                      </>
                    ))}
                  </Sliderify>
                </div>
                <div class="col s9">
                  <div className="item-desc">
                    <span className="title yellow-text text-darken-4">
                      <b>{item.Name}</b>
                    </span>
                    <p>{item.Description}</p>
                    <span>
                      <b>Price: <span class="WebRupee">&#x20B9;</span> {item.Price}</b> 
                    </span>&nbsp;&nbsp;
                    <span><b>Serial No : </b>{item.Serial_No}</span>&nbsp;&nbsp;
                   
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      className="waves-effect waves-light btn pink remove"
                      onClick={() => {
                        this.handleRemove(item._id);
                      }}
                    >
                      Remove
                    </button>
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <>
        {this.state.checkoutSuccess ? (
          <div class="" style={{ padding : "10px"}}>
          <h2 style={{fontWeight: "700" , fontSize: "24px"}}>
              Thank you for your order. We have notified the Admin Team. We will
              reach out to you shortly.
            </h2>
            <button
              onClick={() =>
                (window.location.href = "https://assetportal.mgdevops.com/")
                // (window.location.href = "http://localhost:3000/")
                
                
              }
              class="btn waves-effect waves-light left"
            >
              <i class="material-icons left">home</i> Go to Assets
            </button>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <button
              onClick={() =>
                (window.location.href =
                  "https://assetportal.mgdevops.com/orders")
                // (window.location.href =
                //   "http://localhost:3000/orders")
              }
              class="btn waves-light red"
            >
              <i class="material-icons left">
                <span class="material-symbols-outlined">
                  shopping_cart_checkout
                </span>
              </i>{" "}
              Go to Orders
            </button>
          </div>
        ) : (
          <p>Your Assets Portal Cart is empty.</p>
        )}
      </>
    );
    return (
      <div className="container">
        <div className="cart">
          <h5>Order Summary:</h5>
          <ul className="collection">{addedItems}</ul>
        </div>
        {this.state.cart.length ?  <Recipe
          TotalAmt={this.state.total}
          parentCallback={this.handleCallback}
        /> : ""}
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // items: state.addedItems,
    user: state.user,
    //addedItems: state.addedItems
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
    addQuantity: (id) => {
      dispatch(addQuantity(id));
    },
    subtractQuantity: (id) => {
      dispatch(subtractQuantity(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
