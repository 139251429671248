import React, { Component } from "react";
import { connect } from "react-redux";
import { orderedItems } from "../actions/cartActions";
import { hostUrl } from "../../App";
class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      success: false,
      user: {},
      status_error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    console.log(event.target.name);
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }
  handleSubmit(event) {
    // console.log("object",event);
    event.preventDefault();
    if (this.validate()) {
      // console.log(this.state);
      let input = {};
      input["Mobilenumber"] = "";
      input["email"] = "";
      this.setState({ input: input });
      console.log("Mobile Numver submited Successfully", this.state.input);

      // this.props.orderedItems(this.state.input);
      const body = this.state.input;
      body["userId"] = this.props.user._id;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${this.props.user.token}`
      );
      console.log("ORDER_ITEM :", body);
      var raw = JSON.stringify(body);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${hostUrl}/api/v1/assets/checkout`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(JSON.parse(result));
          if (JSON.parse(result).status === 401) {
            this.setState({
              status_error: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status_error: true,
          });
          console.log("error", error);
        });

      this.setState({
        success: true,
      });

      // localStorage.setItem("Cart", JSON.stringify([]));
      // localStorage.setItem("Total", 0)
    }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["Mobilenumber"]) {
      isValid = false;
      errors["Mobilenumber"] = "Please enter your Mobile Number.";
    }

    if (typeof input["Mobilenumber"] !== "undefined") {
      var pattern = new RegExp(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
      );
      if (!pattern.test(input["Mobilenumber"])) {
        isValid = false;
        errors["Mobilenumber"] = "Please Enter Number Only";
      } else if (input["Mobilenumber"].length !== 10) {
        isValid = false;
        errors["Mobilenumber"] = "Please enter valid  Mobile Number.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  render() {
    return (
      <div className="container">
        {this.state.success ? (
          this.state.status_error ? (
            <div className="main-container">
              <div className="row header">
                <h4 className="col-sm-12 ">Error </h4>
              </div>
              <div className="">
                <div className="check-background">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg7blG4XFpVsxRYjIz-1PohJF6uum-stKLPA&usqp=CAU"
                    alt=""
                  />
                </div>

                <div className="check-shadow"></div>
                <p>Oops, Something went wrong . Please try again later.</p>
              </div>
            </div>
          ) : (
            <div className="main-container">
              <div className="row header">
                <h4 className="col-sm-12 ">Thank you for your order </h4>
              </div>
              <div className="">
                <div className="check-background">
                  <svg
                    viewBox="0 0 65 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 25L27.3077 44L58.5 7"
                      stroke="white"
                      stroke-width="13"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className="check-shadow"></div>
                <p>
                  You will receive an email from the operations team with more
                  details about your order.
                </p>
              </div>
            </div>
          )
        ) : (
          <>
            <div className="row header">
              <h3 className="col-sm-12 ">Contact Info</h3>
            </div>
            <form onSubmit={(data) => this.handleSubmit(data)}>
              <div className="row form-group">
                <div className="col-sm-4">
                  <label className="control-label col-sm-4" for="Mobilenumber">
                    Email Address:
                  </label>
                </div>
                <div className="col-sm-8">
                  <input
                    type="email"
                    name="email"
                    value={this.state.input.email}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Enter Email Address"
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-4">
                  <label className="control-label col-sm-4" for="Mobilenumber">
                    Mobile Number:
                  </label>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="Mobilenumber"
                    value={this.state.input.Mobilenumber}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    id="phone"
                  />
                </div>
                <div className="text-danger mrgnbtn red-text">
                  {this.state.errors.Mobilenumber}
                </div>
                <p>
                  Note :- Please click on confirm button to submit your order.
                  Operations team will reach out to you with details of payment
                  and asset handover
                </p>
              </div>

              <input
                type="submit"
                value="Submit"
                className="btn btn-info mrgnbtn"
              />
            </form>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.items,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderedItems: (item) => {
      dispatch(orderedItems(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
