import { ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,Delete_Item , ADD_ITEM,Edit_Item,ORDER_ITEM,ORDER_CANCEL,ORDER_APPROVE , ORDER_REJECT} from './action-types/cart-actions'

//add cart action
export const addToCart= (id)=>{
    return{
        type: ADD_TO_CART,
        id
    }
}
export const deleteItem= (id)=>{
    return{
        type: Delete_Item,
        id
    }
}
//order Item
export const orderedItems= (info)=>{
    return{
        type: ORDER_ITEM,
        info
    }
}
export const editItem= (item)=>{
    return{
        type: Edit_Item,
        item
    }
}
// add item in action
export const addItem=(item)=>{
    return{
        type: ADD_ITEM,
        item
        
    }
}
//remove item action
export const removeItem=(id)=>{
    return{
        type: REMOVE_ITEM,
        id
    }
}
export const orderCancel=(id)=>{
    return{
        type: ORDER_CANCEL,
        id
    }
}


export const orderApprove=(id)=>{
    return{
        type: ORDER_APPROVE,
        id
    }
}
export const orderReject=(id)=>{
    return{
        type: ORDER_REJECT,
        id
    }
}
// subtract qt action
export const subtractQuantity=(id)=>{
    return{
        type: SUB_QUANTITY,
        id
    }
}
//add qt action
export const addQuantity=(id)=>{
    return{
        type: ADD_QUANTITY,
        id
    }
}

