import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Cart from "./components/Cart";
import Admin from "./components/Admin";
import Orders from "./components/Orders";
import Reports from "./components/Reports";
import { config } from "./config";
import { browserHistory } from "react-router-dom";
import microsoft from "./microsoft.png";
import {
  PublicClientApplication,
  LogLevel,
  BrowserCacheLocation,
} from "@azure/msal-browser";
import axios from "axios";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isAuthenticated: false,
      user: {},
      Total : 0
    };
    // this.login = this.login.bind(this)
    this.PublicClientApplication = new PublicClientApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        // cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                return;
            }
          },
        },
      },
    });
  }

  handleTotal = (total) => {
    this.setState({Total: total});
    console.log("inside function :",total);
}
  async componentDidMount() {
    console.log("App ja Total :",this.state.Total);
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    const activeUser = this.PublicClientApplication.getAllAccounts();
    console.log("getActiveAccount :", activeUser);
    console.log("sessionUser :", sessionUser);
    if (activeUser.length > 0 && sessionUser != null) {
      this.setState({ isAuthenticated: true });
    } else {
      if (activeUser.length > 0 && sessionUser == null) {
        // console.log(activeUser.username ,activeUser.name )
        // const registerData = JSON.stringify({
        //   email: activeUser[0].username,
        //   name: activeUser[0].name,

        // });
        // this.loginWithApi(registerData);
        const request = {
          scopes: ['user.read','Mail.Send'],
          account: activeUser[0],
        };
        const accessToken = await this.PublicClientApplication.acquireTokenSilent(
          request
        )
          .then((response) => {
            console.log("access token :", response.accessToken);
  
            let registerData = JSON.stringify({
              email: activeUser[0].username,
              name: activeUser[0].name,
              AccessToken: response.accessToken,
            })
  
            this.loginWithApi(registerData);
  
            // this.setState({ isAuthenticated: true });
          })
      } else {
        sessionStorage.clear();
        localStorage.clear();
        this.setState({ isAuthenticated: false });
      }
    }
  }

  loginWithApi(registerData) {
    let res_config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://assetportal.mgdevops.com:4434/api/v1/auth/register",
      headers: {
        "Content-Type": "application/json",
        Cookie:
          "connect.sid=s%3AHXoc_ZtLd8zBbCytl0_c9M5P7hq_entH.ORpTbdDxfIsx5i%2BJ3y36Ucjt%2Ff9MKUtQmQA8S7W8wlI",
      },
      data: registerData,
    };

    axios
      .request(res_config)
      .then((response) => {
        console.log("user data :",response.data.user);
        sessionStorage.setItem("user", JSON.stringify(response.data.user));
        window.location.replace("/");
      })
      .catch((error) => {
        console.log("error user :",error.response);
        if (error.code !== "ERR_NETWORK") {
          console.log(error.response.status);
          this.setState({ isAuthenticated: false });
          if (error.response.status === 302) {
            console.log("user :", error.response.data.userDB);
            sessionStorage.setItem(
              "user",
              JSON.stringify(error.response.data.userDB)
            );
            // window.location.replace("/");
            window.location.replace("/");
            // window.location.reload(true);
          }
        }
      });
  }
  async login() {
    // // const user = sessionStorage.getItem("user");
    // const user =  this.PublicClientApplication.getActiveAccount()
    // console.log("app user :", user);
    // if (user === null) {
    try {
      const data = await this.PublicClientApplication.loginPopup({
        scopes: config.scopes,
        prompt: "select_account",
      });

      const activeUser = this.PublicClientApplication.getAllAccounts();
      const request = {
        scopes: ["User.Read"],
        account: activeUser[0],
      };
      const accessToken = await this.PublicClientApplication.acquireTokenSilent(
        request
      )
        .then((response) => {
          // console.log("access token :", response.accessToken);

          let registerData = JSON.stringify({
            email: data.account.username,
            name: data.account.name,
            AccessToken: response.accessToken,
          });

          this.loginWithApi(registerData);

          this.setState({ isAuthenticated: true });
        })
        .catch((error) => {
          // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
          console.log("Token error :", error);
          alert(error);
          return null;
        });

      // console.log(
      //   "email  :",
      //   data.account.username,
      //   "name :",
      //   data.account.name
      // );
      // const axios = require("axios");
    } catch (err) {
      this.setState({
        isAuthenticated: false,
        user: {},
        error: err,
      });
    }
    // } else {
    //   this.setState({ isAuthenticated: true });
    // }
  }
  render() {
    return (
      <BrowserRouter>
        {this.state.isAuthenticated ? (
          <div className="App ">
            <Navbar cartTotal={this.state.Total} />
            <Switch>
              {/* <Route exact path="/" component={Home} cartTotal="hello" /> */}
              <Route exact path="/"> <Home onSelectTotal={this.handleTotal} /> </Route>
              <Route exact path="/cart"><Cart onSelectTotal={this.handleTotal}/></Route> 
              {/* component={Cart}  /> */}
              <Route path="/newItem" component={Admin} />
              <Route path="/orders" component={Orders} />
              <Route path="/reports" component={Reports} />
            </Switch>
          </div>
        ) : (
          <>
            <Navbar />

            <div class="container1">
              <div class="row" style={{ width: "650px" }}>
                <h4 style={{ textAlign: "center" }}>
                <b>
                    Login with Microsoft Account
                  </b>
                </h4>
                <div class="vl">
                  {/* <span class="vl-innertext">or</span> */}
                </div>

                <div class="cols-1">
                <h5>
                    Welcome to the MicroGenesis asset
                    portal.
                  </h5>
                </div>

                <div class="cols-1" style={{ paddingTop: "40px" }}>
                  <button
                    onClick={() => this.login()}
                    style={{
                      padding: "3px",
                      border: "none",
                      backgroundColor: "black",
                    }}
                  >
                    <img
                      src={microsoft}
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                    />
                    <span
                      style={{
                        fontSize: "18px",
                        color: "white",
                        position: "relative",
                        top: "-7px",
                      }}
                    >
                      {" "}
                      Sign in with Microsoft &nbsp;
                    </span>{" "}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </BrowserRouter>
    );
  }
}

export default App;
export const hostUrl = "https://assetportal.mgdevops.com:4434";
// export const hostUrl = "http://localhost:5000";
