import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../images/comp_logo.png";
import user from "../images/user.jpg";
import axios from "axios";
import Avatar from "react-avatar";
import { padding } from "@mui/system";
import Switch from "react-switch";
import { hostUrl } from "../App";
class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      user_define: {},
      pathUrl: "/",
      profiles: false,
      // cart_items:0
      cart_total: 0,
      checked: false,
      // cart_checked : false
      cart_checked: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(cart_checked) {
    this.state.cart_checked.Enable = cart_checked;
    const changed = this.state.cart_checked;
    
    
  
    let data = JSON.stringify(changed);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/enableCart`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,        
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.enable_cart);
        this.setState({ cart_checked: response.data.enable_cart[0] });
        window.location.reload(true);

      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount = () => {
    if (localStorage.getItem("userMode") == null) {
      localStorage.setItem("userMode", false);
    }
    // const axios = require('axios');

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/enableCart`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("enable cart :", response.data.enable_cart[0]);
        this.setState({ cart_checked: response.data.enable_cart[0] });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      user_define: this.props.user,
      pathUrl: this.props.browserUrl,
      checked: localStorage.getItem("userMode") === "false" ? false : true,
    });
  };

  dropDownProfile = () => {
    // console.log("Profiles");
    this.setState({ profiles: !this.state.profiles });
  };
  profileClose = () => {
    sessionStorage.clear();
    localStorage.clear();
    // this.PublicClientApplication.logout();
    // sessionStorage.removeItem('user')
    // window.location.href = "https://assetportal.mgdevops.com/"
    window.location.reload(true);
    this.setState({ profiles: false });
  };
  // settingFun = (val) => {
  //   this.setState({cart_total :val})
  // }

  handleCheck = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    user.role = !this.state.checked ? "user" : "admin";

    sessionStorage.setItem("user", JSON.stringify(user));

    localStorage.setItem("userMode", !this.state.checked);

    window.location.reload(true);
    // window.location.href = "http://localhost:3000/";
    window.location.href = "https://assetportal.mgdevops.com/";
    // console.log("set data :",localStorage.getItem("userMode"));
    this.setState({ checked: !this.state.checked });
  };
  render() {
    return (
      <div class="navbar-fixed">
        <nav className="nav-wrapper sticky">
          <div className="container">
           
            <img
              src={logo}
              alt=""
              style={{ width: "auto", height: "55px", paddingTop: "6px" }}
            
            />{" "}
            &nbsp; &nbsp;

           
            
            <Link to="/" className="brand-logo">
              Assets Portal
            </Link>
            {Object.keys(this.state.user_define).length ? (
              <ul className="right ">
                <li>
                  <Link to="/cart">
                    <i className="material-icons">shopping_cart</i>{" "}
                    {this.props.cartTotal == 0 ? null : (
                      <span
                        class=""
                        style={{
                          position: "absolute",
                          top: "8px",
                          color: "#ee6e73",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;{" "}
                        <span
                          style={{
                            backgroundColor: "#fff",
                            padding: "1px 7px",
                            borderRadius: "10px",
                          }}
                        >
                          <b>{this.props.cartTotal}</b>
                        </span>
                      </span>
                    )}
                    {/* <span className="cart-total--item">{this.props.cartTotal}</span> */}
                  </Link>
                </li>
                <li>
                  <Link to="/">Assets </Link>
                </li>

                <li>
                  <Link to="/orders">Orders </Link>
                </li>
                {this.state.user_define.role === "user" ||
                this.state.user_define.role === undefined ? null : (
                  <>
                    <li>
                      <Link to="/newItem">Add Inventory</Link>
                    </li>

                    <li>
                      <Link to="/reports">Reports </Link>
                    </li>
                  </>
                )}
                <li>
                  {/* <img src={user} alt="" onClick={this.dropDownProfile} class="circle responsive-img" style={{height : "55px" , paddingTop : "3px"}} /> */}
                  <Avatar
                    size="50"
                    round={true}
                    onClick={this.dropDownProfile}
                    color={Avatar.getRandomColor("sitebase", [
                      "sandybrown",
                      "teal",
                      "peru",
                    ])}
                    name={this.state.user_define.name}
                  />
                </li>
                {/* <a class="dropdown-trigger" href="#!" data-target="dropdown1">Dropdown<i class="material-icons right">arrow_drop_down</i></a></li> */}
              </ul>
            ) : null}
          </div>
        </nav>
        {this.state.profiles ? (
          <div
            class="row"
            style={{
              float: "right",
              position: "fixed",
              right: "2px",
              top: "85px",
            }}
          >
            <div class="col s2 m2">
              <div class="card blue-grey darken-1">
                <div class="card-content white-text">
                  <span class="card-title">{this.state.user_define.name}</span>
                  {this.state.user_define.role === "user" ||
                  this.state.user_define.role === undefined ? null : (
                    <>
                      <label style={{ color: "white", fontSize: "18px" }}>
                        <input
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            margin: "10px",
                            width: "18px",
                            height: "18px",
                          }}
                          type="checkbox"
                          checked={this.state.checked}
                          onChange={this.handleCheck}
                        />
                        User Mode
                      </label>
                     <div style={{paddingTop : "10px"}}>
                      <label htmlFor="material-switch" >
                        <Switch
                          checked={this.state.cart_checked.Enable}
                          onChange={this.handleChange}
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={17}
                          width={38}
                          className="react-switch"
                          id="material-switch"
                        />
                        <span
                          style={{
                            position : "absolute",
                            color: "white",
                            fontSize: "20px",
                            marginTop: "-5px",
                          }}
                        >
                          {" "}
                          &nbsp;Enable Cart
                        </span>
                      </label>
                      </div>
                    </>
                  )}
                </div>

                <div class="card-action">
                  <Link onClick={this.profileClose}>Logout</Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // items: state.items,
    user: state.user,
    browserUrl: state.browserUrl,
    Total: state.total,

    // addedItems:state.addedItems
  };
};

export default connect(mapStateToProps)(Navbar);
