// import { act } from "@testing-library/react";
import {
  ADD_TO_CART,
  REMOVE_ITEM,
  // SUB_QUANTITY,
  // ADD_QUANTITY,
  ADD_SHIPPING,
  ADD_ITEM,
  Delete_Item,
  Edit_Item,
  ORDER_ITEM,
  ORDER_CANCEL,
  // ORDER_APPROVE,
  // ORDER_REJECT,
} from "../actions/action-types/cart-actions";
// import axios from "axios";
// import { useHistory } from 'react-router';

const initState = {
 
  user:  JSON.parse(sessionStorage.getItem('user')) !== null ? JSON.parse(sessionStorage.getItem('user')) : {}
  


};


const cartReducer = (state = initState, action) => {
  console.log("action :", action);
  //INSIDE HOME COMPONENT
  if (action.type === ADD_ITEM) {
    console.log("ADD_ITEM :");    
  }

  if (action.type === Delete_Item) {
    console.log("Delete_Item");

    
  }

  if (action.type === Edit_Item) {
    console.log("Edit_Item");
   
  }

  if (action.type === ADD_TO_CART) {
    console.log("ADD_TO_CART:");
   
  }

  if (action.type === REMOVE_ITEM) {
    console.log("remove :");
   
  }

  //Oreder items component
  if (action.type === ORDER_ITEM) {
    console.log("ORDER_ITEM :");
  
    
  }


  if (action.type === ADD_SHIPPING) {
    console.log("ADD_SHIPPING");
  }

  if (action.type === ORDER_CANCEL) {
    console.log("ORDER_CANCEL");
   
  }

  if (action.type === "ORDER_APPROVE") {
    console.log("ORDER_APPROVE :");
   
  } else {
    return state;
  }
};

export default cartReducer;
