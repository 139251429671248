import React, { Component } from "react";
import { connect } from "react-redux";
import Form, {
  Input,
  Select,
  FormButton,
  Row,
  ImageUpload,
  Switch,
  TextArea,
} from "react-form-component";
import { editItem } from ".././actions/cartActions";
import { hostUrl } from "../../App";

import axios from "axios";
import dateFormat from "dateformat";
class EditItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      image_path: null,     
      assets : [],
      cart:[],
      serialValidate: false,
      quantityEdit:null,
      Categories:[      
      ],
      emptyImages :false
    }
    this.handleClick = this.modelClose.bind(this)
    this.removeSelectedImage = this.removeSelectedImage.bind(this);
  }
  componentDidMount = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets`,
      headers: {
        "Content-Type": "application/json",
        Authorization:
        `Bearer ${this.props.user.token}`
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };
    axios
      .request(config)
      .then((response) => {
        console.log("app js :", response.data.res);
        this.setState({ assets: response.data.res });
        // localStorage.setItem("Assets", JSON.stringify(response.data.res));
      })
      .catch((error) => {
        console.log("err :", error);
      });

      let cart_config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets/shopping/cart/${this.props.user._id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
          `Bearer ${this.props.user.token}`
            // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
        },
      };
  
      axios
        .request(cart_config)
        .then((response) => {
          // console.log(response.data.cart);
          const res = this.state.assets.filter((obj) =>
            response.data.cart.includes(obj._id)
          );
          // console.log(res);
          this.setState({
            cart: res,
          });
          
        })
        .catch((error) => {
          console.log("error :", error);
        })

    // console.log("total items :",this.props.item);
    // const select_filt = this.state.select.reduce((a, c) => {
    //   if (c.value.toUpperCase().includes(this.props.item.Status.toUpperCase()))
    //     a.unshift(c);
    //   else a.push(c);
    //   return a;
    // }, []);

    let CategoryConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/category`,
      headers: {
        Authorization: `Bearer ${this.props.user.token}`,
      },
    };

    axios
      .request(CategoryConfig)
      .then((response) => {
        console.log("category :", response.data.category);
        this.setState({
          Categories: response.data.category,
        });
      })
      .catch((error) => {
        
        console.log(error);
        
      });

    this.setState({
      data: this.props.item,
      // select: select_filt,
    });
  };
  formSubmit = (data) => {
    data.image =  this.state.data.image
    console.log("form data 1:", data );
    const serialVal = this.state.assets.filter(
      (item) => item.Serial_No === data.Serial_No && item._id !== this.props.item._id
    );
    // console.log("object : ",serialVal ,this.props.item._id );
    if(serialVal.length){
      
      this.setState({serialValidate : true})
    }else if (data["image"].length === 0){
      this.setState({emptyImages : true})
    }else{
       data["_id"] = this.props.item._id;

    // this.props.editItem(data);
    const filter = this.state.assets.filter((obj) => obj._id === data._id);
    // console.log("filter :", filter[0]);

    const keys = Object.keys(data);
    const res = {};
    var formData = new FormData();
    keys.map((item) => {
      if (data[item] !== filter[0][item]) { 
        
        if(item == "image"){
          
          if(data[item].filter((obj)=>!("url" in obj)).length){
            // console.log("without url :", data[item].filter((obj)=>!("url" in obj)));
            const without = data[item].filter((obj)=>!("url" in obj))
            for (let i = 0; i < without.length; i++) {           
                  formData.append("files", without[i]);
                } 
          }
          if(data[item].filter((obj)=>"url" in obj)){
            console.log("with url :",data[item].filter((obj)=>"url" in obj) );
            res[item] = data[item].filter((obj)=>"url" in obj)
          }
          
        }else{
          res[item] = data[item];
        }        
      }
    });
    
    

    if(formData.get("files") !== null){
      // console.log("form get :",formData.get("files"));
      this.FileUpload(data , formData)
    }
    

    let dataBody = JSON.stringify(res)
    //JSON.stringify(res);
   
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/${data._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization:
        `Bearer ${this.props.user.token}`
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
      data: dataBody,
    };

    axios
      .request(config)
      .then((response) => {     
        this.setState({
          assets : response.data.result,
          // cart : res
        })
        console.log("update  :",response.data.result);

        // this.props.parentCallback(response.data.result);
        // window.location.reload(true);        
      })
      .catch((error) => {
        console.log(error);
      });

      this.setState({serialValidate : false , emptyImages : false})
      
    }   
    
  };
  FileUpload=(data , formData)=>{
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/${data._id}`,
      headers: {
        // "Content-Type": "application/json",
        Authorization:
        `Bearer ${this.props.user.token}`
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {     
        this.setState({
          assets : response.data.result,
          // cart : res
        })
        console.log("File :",response.data.result);

        this.props.parentCallback(response.data.result);
        // window.location.reload(true);        
      })
      .catch((error) => {
        console.log(error);
      });

  }
 
  removeSelectedImage = (index) => {
    
   const deleteImg =  this.state.data
   deleteImg.image = deleteImg.image.filter((item,ind)=>ind !== index)
    this.setState({
      data : deleteImg
    })
    
    
  };

  handleChange = (e) => {
    console.log(e.target.files[0]);
    const imageData = this.state.data
    imageData.image = [...Object.values(e.target.files) , ...imageData.image]
    this.setState({ data: imageData , emptyImages : false});
    
    
  };
  modelClose = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <div className="editForm">
        <div className="editFormTitle">
          <p class="flow-text">Update Item</p>
        </div>
        <Form
          fields={[
            "Name",            
            "Manufacturer_Year",
            "Price",
            "Quantity_Available",
            "Display",           
            "Description",
            "image",
            "Serial_No",
            "Category"
          ]}
          mandatory={[
            "Name",           
            "Manufacturer_Year",
            "Price",
            "Quantity_Available",
            "Serial_No",            
            "Description",
            "Category"            
          ]}
          onChange={(e)=>this.setState({quantityEdit : e.Category})}
        >
          <Row>
         
            <Input
              name="Name"
              label="Name"
              initialValue={this.state.data.Name}
            />
            <Input
              name="Serial_No"
              initialValue={this.state.data.Serial_No}
              label="Serial No"
              help={
                this.state.serialValidate ? (
                  <span  style={{ color: "red" }}>
                    {" "}
                    The specified serial no. already exist
                  </span>
                ) : (
                  ""
                )
              }
            />
         
          </Row>
          <Row>
            <Input
              name="Price"
              type="number"
              label="Price"
              initialValue={this.state.data.Price}
            />
            <Input
              label="Manufacturer Year"
              name="Manufacturer_Year"
              type="date"
              initialValue={dateFormat(this.state.data.Manufacturer_Year, "yyyy-mm-dd") }
              
            />
           
          </Row>

          <Row>
            <Input
              name="Quantity_Available"
              type="number"
              label="Quantity Available"
              initialValue={this.state.data.Quantity_Available}
              disabled={this.state.quantityEdit === null || this.state.quantityEdit === undefined ? true:
                (this.state.quantityEdit.toLowerCase() === "keyboard" ||
                this.state.quantityEdit.toLowerCase() === "keyboards" ||
                 this.state.quantityEdit.toLowerCase() === "mouse" ||
                 this.state.quantityEdit.toLowerCase() === "mouses" ) ? false : true
                  }
            />           
           
             <Select
              className=""
              // help="Help text"
              label="Category"
              name="Category"
              options={this.state.Categories}
              placeholder="select the status"
              initialValue={this.state.data.Category}
              prefix=""
              suffix=""
            />
          </Row>
          <Row>
            
            <TextArea
              className=""
              help="Help text"
              // initialValue=""
              label="Description"
              min={5}
              name="Description"
              placeholder="Placeholder text"
              prefix=""
              rows={2}
              suffix=""
              initialValue={this.state.data.Description}
            />
          </Row>
          <Row>
            <Switch
              className=""
              help=""
              label="Display on Live page?"
              name="Display"
              // initialValue={true}
              text="Do you want to display item in home page"
              initialValue={this.state.data.Display}
            />
            {/* {console.log("image data :",this.state.data.image)} */}
            <input            
              files={this.state.data.image}              
              name="image"
              multiple              
              onChange={this.handleChange}
              type="file"
              accept="image/*"
              // help={
              //   this.state.emptyImages ? (
              //     <span  style={{ color: "red" }}>
              //       {" "}
              //       The specified serial no. already exist
              //     </span>
              //   ) : (
              //     ""
              //   )
              // }
            />
           
            
          </Row>
          <Row>
            {this.state.data.image !== undefined
              ? this.state.data.image.map((Ind ,index) => {
                  return (
                    <>
                      {/* <img src={ URL.createObjectURL(this.state.image_path[Ind])}  alt=""/> */}
                      <div style={styles.preview} key={Ind}>
                        <img
                          src={"url" in Ind ? Ind.url.includes(hostUrl) ? Ind.url : hostUrl+"/"+Ind.url :URL.createObjectURL(Ind) }
                          style={styles.image}
                          alt="Thumb"
                        />
                        <span
                          onClick={() => this.removeSelectedImage(index)}
                          style={styles.delete}
                        >
                          Delete
                        </span>
                      </div>
                     
                    </>
                  );
                })
              : ""}
               {this.state.emptyImages ? (
                  <span  style={{ color: "red" }}>
                    {" "}
                    No images found
                  </span>
                ) : (
                  ""
                )}
          </Row>

          <FormButton onClick={(fields) =>{ 
            this.formSubmit(fields)
             this.handleClick()
             }}>
            Save
          </FormButton>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // asset: state.items,
    user:state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editItem: (item) => {
      dispatch(editItem(item));
    },
  };
};
export default connect( mapStateToProps,mapDispatchToProps)(EditItemForm);

const styles = {
  container: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // paddingTop: 50,
  },
  preview: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: 150, maxHeight: 100 },
  delete: {
    cursor: "pointer",
    padding: 10,
    background: "red",
    color: "white",
    border: "none",
     justifyContent: "center",
    alignItems: "center",
  },
};
