import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { orderCancel } from "./actions/cartActions";
import { Sliderify } from "react-sliderify";
import { confirmAlert } from "react-confirm-alert";
// import { Height } from "@mui/icons-material";
import { Modal } from "react-responsive-modal";
import { hostUrl } from "../App";
class Orders extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      cancelOpen :false,
      cancelId : null
    };
  }
  componentDidMount = () => {
    let checkout_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/checkout/${this.props.user._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };

    axios
      .request(checkout_config)
      .then((response) => {
        console.log(response.data.orders);
        this.setState({
          data: response.data.orders.reverse(),
        });        
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleRemove = (id) => {
    console.log("handle remove", id);
this.setState({cancelOpen : true , cancelId : id})


  };
  cancelItem=(id)=>{
   
    // const axios = require("axios");
    let data = JSON.stringify({
      id: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/order/cancel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const myOrders = this.state.data.filter((obj) => obj.id !== id);
        this.setState({ data: myOrders });

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onCancelOrder = () => {
    // this.setState({ deleteOpen: true });
    console.log("conform id :",this.state.cancelId);
   
    this.cancelItem(this.state.cancelId)
    this.setState({ cancelOpen: false   })
  };

  onCloseDeleteModal = () => {
    this.setState({ cancelOpen: false });
  };
  render() {
    let addedItems = this.state.data.length ? (
      this.state.data.map((item) => {
        return (
         
          <div class="col s12 m8 offset-m2 l6 offset-l3">
            <div class="card-panel grey lighten-5 z-depth-1">
              <div class="row valign-wrapper">
                <div class="col s3">
                  <Sliderify showNavButtons={true} showSpot={false}>
                    {item.image.map((fadeImage, index) => (
                      <>
                        <img
                          // src={fadeImage.url}
                          src={fadeImage.url.includes(hostUrl) ? fadeImage.url  : hostUrl+"/"+ fadeImage.url}
                          alt="slide images"
                          style={{ height: "200px" }}
                        />
                      </>
                    ))}
                  </Sliderify>
                </div>
                <div class="col s9">
                  <div className="item-desc">
                    <span className="title yellow-text text-darken-4">
                      <b>{item.Asset}</b>
                    </span>
                    <p>{item.Description}</p>
                    {/* <br /> */}
                    <p>
                      <span>
                        {item.Status === "Open" ? (
                          <span className="statusCol">
                            {" "}
                            <span class="dot"></span> {item.Status}
                          </span>
                        ) : item.Status === "Approve" ? (
                          <span className="statusCol">
                            <span class="dot"></span> Closed
                          </span>
                        ) : (
                          <span className="statusCol_1">
                            <span class="dot1"></span> {item.Status}
                          </span>
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      <b>Price: <span class="WebRupee">&#x20B9;</span> {item.Price}</b>&nbsp;&nbsp;&nbsp;&nbsp;
                      <span><b>Serial No : </b>{item.serial_no}</span>&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {item.Status === "Open" ? (
                        <span>
                          <button
                            className="waves-effect waves-light btn pink remove"
                            onClick={() => {
                              this.handleRemove(item._id);
                            }}
                          >
                            Cancel
                          </button>
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <p>Nothing.</p>
    );
    return (
      <div className="container">
        <div className="cart">
          <h5>Ordered Status:</h5>
          <ul className="collection">{addedItems}</ul>
        </div>
        <Modal open={this.state.cancelOpen} onClose={this.onCloseDeleteModal}>
          <div style={{width : "500px"}}>
          <h5>Confirm to Cancel :</h5>
          <p>Are you sure you want to cancel the order?</p>
          <div class="right-align">
          <button class="waves-effect waves-light btn" onClick={this.onCancelOrder}>Yes</button>&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="btn waves-effect waves-light red" onClick={this.onCloseDeleteModal}>No</button>
          </div>          
          </div>          
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderCancel: (item) => {
      dispatch(orderCancel(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
// export default Orders;
