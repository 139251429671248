//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const ADD_SHIPPING = 'ADD_SHIPPING';
export const Delete_Item = 'Delete_Item';
export const Edit_Item = 'Edit_Item';
export const ORDER_ITEM = 'ORDER_ITEM'
export const ORDER_CANCEL = 'ORDER_CANCEL'
export const ORDER_APPROVE = 'ORDER_APPROVE'
export const ORDER_REJECT = 'ORDER_REJECT'

