import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Modal } from "react-responsive-modal";
import { FormThemeProvider } from "react-form-component";
import Form, {
  Input,
  Row,
  FormButton,
  TextArea,
  Select,
} from "react-form-component";
import { orderApprove, orderReject } from "./actions/cartActions";
import { JsonToExcel } from "react-json-to-excel";
import dateFormat from "dateformat";
import { hostUrl } from "../App";
// import dateFormat, { masks } from "dateformat";
class Reports extends Component {
  constructor() {
    super();
    this.state = {
      orderData: [],
      filterDate: [],
      open: false,
      itemId: null,
      itemStatus: "",
      reasonModel: false,
      reason: "",
      filterDrop : [],
      columns: [
        {
          name: "Order Date",
          selector: (row) =>
            new Date(row.createdAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            }),
          // dateFormat(row.createdAt, "isoDateTime") ,
          sortable: true,
          maxWidth: "180px",
        },

        {
          name: "Asset Name",
          selector: (row) => row.Asset,
          sortable: true,
        },
        {
          name: "Price",
          selector: (row) => row.Price,
          sortable: true,
          maxWidth: "100px",
        },
        {
          name: "Email",
          selector: (row) => row.Requestor_Mail,
          sortable: true,
        },
        {
          name: "Name",
          selector: (row) => row.Requestor_Name,
          sortable: true,
          maxWidth: "150px",
        },
        {
          name: "Approved By",
          selector: (row) => ("Approved_By" in row ? row.Approved_By : ""),
          sortable: true,
          maxWidth: "200px",
        },
        {
          name: "Status",
          selector: (row) =>
            row.Status === "Open" ? (
              <span>{row.Status}</span>
            ) : row.Status === "Cancel" ? (
              <span style={{ color: "red" }}>
                {" "}
                <span class="dot1"></span> Cancelled
                {/* {row.Status} */}
              </span>
            ) : (
              <span style={{ color: "green" }}>
                <span class="dot"> </span> Approved
                {/* {row.Status} */}
              </span>
            ),
          sortable: true,
          maxWidth: "120px",
        },
        {
          name: "Admin Action /",
          cell: (row) => (
            <button
              className="waves-effect waves-light btn"
              onClick={this.handleButtonClick}
              id={row._id}
              name="Approve"
              style={
                row.Status === "Open"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              Approve
            </button>
          ),

          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: "Reason",
          cell: (row) =>
            row.Status === "Open" ? (
              <button
                className="waves-effect waves-light btn pink remove"
                onClick={this.handleButtonClick}
                id={row._id}
                name="Cancel"
              >
                Cancel
              </button>
            ) : (
              <span
                onClick={() =>
                  this.setState({ reasonModel: true, reason: row.Reason })
                }
                style={{ color: "blue" }}
              >
                <u>
                  {row.Reason === undefined
                    ? row.Reason
                    : row.Reason.length === 0
                    ? row.Reaso
                    : row.Reason.substring(0, 15) + "..."}{" "}
                </u>
              </span>
            ),

          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
  }
  handleButtonClick = (e) => {
    console.log("click :", e.target.id, e.target.name);

    if (e.target.name === "Approve") {
      let data = JSON.stringify({
        user: this.props.user,
        id: e.target.id,
        Status: e.target.name,
        Reason: { Description: "" },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets/order/status`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.token}`,
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);

          this.setState({
            orderData: response.data.MyOrders,
            filterDate: response.data.MyOrders.reverse(),
          });
          // orderApprove(response.data.MyOrders);
          // window.location.reload();
        })
        .catch((error) => {
          console.log("error :", error);
          if (error.response.status === 401) {
            alert("Unauthorized");
          }
        });
    } else {
      this.setState({
        open: true,
        itemStatus: e.target.name,
        itemId: e.target.id,
      });
    }
  };

  componentDidMount() {
    // const axios = require('axios');

    let checkout_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/orders`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
    };

    axios
      .request(checkout_config)
      .then((response) => {
        console.log("my orders :", response.data.orders);
        function removeDuplicates(arr) {
          console.log("array :",arr);
          return [...new Set(arr)];
      }
      const dropVal = response.data.orders.filter((item) =>"Approved_By" in item).map(({Approved_By}) => Approved_By)
        this.setState({
          orderData: response.data.orders,
          filterDate: response.data.orders.reverse(),
          filterDrop : removeDuplicates(dropVal)
        });


        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    //-------end------------------------------
  }
  modelClose = () => {
    this.setState({ open: false });
  };
  formSubmit = (des) => {
    console.log("form data :", des, this.state.itemStatus, this.state.itemId);

    let data = JSON.stringify({
      user: this.props.user,
      id: this.state.itemId,
      Status: this.state.itemStatus,
      Reason: des,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${hostUrl}/api/v1/assets/order/status`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.token}`,
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDJhYjBiYjVkNmJjMTgxOWFiNGFkOTciLCJpYXQiOjE2ODA1MTkzNTV9.tmxNFTUlS8NgpoUv6HKtREJYl4mJV1BWW_HbGzY5bbM",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // localStorage.setItem("Checkout", JSON.stringify(response.data.MyOrders));
        this.setState({
          orderData: response.data.MyOrders.reverse(),
          filterDate: response.data.MyOrders,
        });
        // orderApprove(response.data.MyOrders)
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error :", error);
        if (error.response.status === 401) {
          alert("Unauthorized");
        }
      });

    this.modelClose();
  };

  filterForm = (data) => {
    var startDateVal = data.from === undefined || data.from ===''  ? "" :new Date(data.from);
    var endDateVal = data.to === undefined || data.to ==='' ? "" : new Date(data.to);
    console.log("filterDate", data);
    
    var dateFilter = this.state.filterDate.filter(           
      function (a)            
      {   
        //Filter function to ensure that the final result set is within a particular range    
           
          const date = dateFormat(a.createdAt.split("T")[0], "yyyy-mm-dd")
          const startDate =startDateVal !== "" ? dateFormat(startDateVal, "yyyy-mm-dd") : ""          
          const endDate = endDateVal !== "" ? dateFormat(endDateVal, "yyyy-mm-dd") : ""
          // return (date) >= startDate && (date) <= endDate;
              
          var rangeAcceptance = {
            minValid: date >= startDate,
            maxValid: date <= endDate
          };            
          var acceptanceResult; //boolean to determine if the relevant range specified is valid

          if (startDate !== "" && endDate !== "") {            
            acceptanceResult = (rangeAcceptance.minValid && rangeAcceptance.maxValid);            
          } else if (startDate !== "") {            
            acceptanceResult = rangeAcceptance.minValid; 
            // console.log("event_date :", a.event_date , "startDateVal :",startDateVal , "startDate :",startDate)
          } else if (endDate !== "") {            
            acceptanceResult = rangeAcceptance.maxValid;            
          } else {            
            acceptanceResult = (1 === 1); //show all results if no specific range has been selected            
          }            
          return (acceptanceResult);
        })
        // console.log("dateFilter :",dateFilter);
        const statusFilter = dateFilter.filter((obj)=>{               
          if(data.status !== undefined && data.status !== "" ){
            return obj.Status === data.status
          }else{
            return true
          }                
        })
        
        const Provided_By = statusFilter.filter((obj)=>{               
          if(data.Approved_By !== undefined && data.Approved_By !== "" ){
            return obj.Approved_By === data.Approved_By
          }else{
            return true
          }                
        })
console.log("orderData :",Provided_By);
    this.setState({ orderData: Provided_By });
   
  };
  reasonModelClose = () => {
    this.setState({ reasonModel: false });
  };
  render() {
    return (
      <div className="pageAlignment">
        <div className="row">
          <div className="col s12 m10">
            <FormThemeProvider>
              <Form fields={["from", "to" ,"status" ,"Approved_By"]}
              //  mandatory={["from", "to"]}
              >
                <Row>
                  <Input
                    label="From"
                    name="from"
                    type="date"
                    // initialValue={dateFormat(this.state.data.Manufacturer_Year, "yyyy-mm-dd") }
                  />
                  <Input
                    label="To"
                    name="to"
                    type="date"
                    // initialValue={dateFormat(this.state.data.Manufacturer_Year, "yyyy-mm-dd") }
                  />
                  <Select
                   className=""
                    name="status"
                    label="Status"
                    options={[{label : "Open" , value :"Open" }, {label :"Approved" , value : "Approve"} , {label :"Cancelled" , value : "Cancel"}]}
                    placeholder=""
                    prefix=""
                    suffix=""
                    
                  />
                  <Select
                    name="Approved_By"
                    label="Approved By"
                    options={this.state.filterDrop}
                  />

                  <FormButton onClick={(fields) => this.filterForm(fields)}>
                    Filter
                  </FormButton>
                </Row>
              </Form>
            </FormThemeProvider>
          </div>
          {/* <div className="col s12 m3  ">
            <button
              className="waves-effect waves-light btn"
              type="button"
              onClick={this.exportData}
            >
              Export as Json
            </button>
          </div> */}
          <div className=" col s12  m2 right-align" style={{paddingTop : '28px'}}>
            <JsonToExcel
              title="Export"
              data={this.state.orderData}
              fileName="Reports"
              btnClassName="custom-classname"
            />
          </div>
        </div>

        <Modal
          open={this.state.open}
          onClose={this.modelClose}
          center
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <div className="editFormTitle" styles={{ maxWidth: "500px" }}>
            <p class="flow-text">
              Add Reason &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
            </p>
          </div>
          <FormThemeProvider>
            <Form fields={["Description"]} mandatory={["Description"]}>
              <Row>
                <TextArea
                  className=""
                  // help="Help text"
                  // initialValue=""
                  label="Reason "
                  min={12}
                  name="Description"
                  placeholder="Placeholder text"
                  prefix=""
                  rows={4}
                  suffix=""
                />
              </Row>

              <FormButton onClick={(fields) => this.formSubmit(fields)}>
                Send
              </FormButton>
            </Form>
          </FormThemeProvider>
        </Modal>
        <br />
        <Modal
          open={this.state.reasonModel}
          onClose={this.reasonModelClose}
          center
        >
          <div className="editFormTitle">
            <p class="flow-text">
              Reason
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <p>{this.state.reason}</p>
        </Modal>

        <DataTable
          fixedHeader
          highlightOnHover
          columns={this.state.columns}
          data={this.state.orderData}
          pagination
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    // items: state.items,
    user: state.user,
    // orderedItem: state.order,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    orderReject: (id) => {
      dispatch(orderReject(id));
    },
    orderApprove: (id) => {
      dispatch(orderApprove(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
