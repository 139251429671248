import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import CRUDTable, {
  Fields,
  Field,
  CreateForm,
  UpdateForm,
  DeleteForm,
} from "react-crud-table";
import { hostUrl } from "../App.js";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

let tasks;



export default function Category(props) {
  tasks = props.category.map((item,ind)=>{
     item.index = ind + 1 
     return item
    });

  const errorNotify = (err) => toast.error(`${err}`);

  const service = {
    fetchItems: (payload) => {
      let result = Array.from(tasks);
      // console.log("featching :",tasks );
      // const res = tasks.map(obj =>{ console.log(!('index' in obj))})
   
      // console.log("featching :",tasks ,  res);
      return Promise.resolve(result);
    },
    create: (task) => {
      const count = tasks.length

     task.value = task.label
      let res;
      // console.log("create :",task);
      const data = JSON.stringify(task);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${hostUrl}/api/v1/assets/category`,
        headers: {
          Authorization: `Bearer ${props.Token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          tasks.push({
            ...response.data.category[response.data.category.length - 1],
            index : count
          });
          const notId = tasks.filter((obj)=> obj.label === task.label &&  obj.value === task.value && !("_id" in  obj))
        if(notId.length){
          tasks.splice(tasks.findIndex(a =>a.label === task.label &&  a.value === task.value && !("_id" in  a)) , 1)
        }
          props.parentCallback(tasks)
         
          
        })
        .catch((error) => {
          console.log("error :", error.response.data);
          if (error.response.data.status === 409) {
            errorNotify(
              `Duplicate error! ${
                Object.keys(error.response.data.err.keyValue)[0]
              } - ${Object.values(error.response.data.err.keyValue)[0]}`
            );
          } else {
            errorNotify("Unknown error!");
          }
        });
        console.log("res :", res);
      tasks.push({
        ...task,
        index : count
        
      });
     
      return Promise.resolve(task);
    },
    update: (data) => {
      console.log("Data :",data);
      // console.log("tasks  :",);
      const filter = tasks.filter((obj)=> obj.index === data.index && "_id" in  obj)
      if(filter.length){
        const task = tasks.find((obj) =>  obj.index === data.index && "_id" in  obj);
        
      task.label = data.label;
      task.value = data.label;
      console.log("tasks :",task);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${props.Token}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        label: data.label,
        value: data.value,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${hostUrl}/api/v1/assets/category/${task._id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));

        const notId = tasks.filter((obj)=> obj.index === data.index && !("_id" in  obj))
        if(notId.length){
          tasks.splice(tasks.findIndex(a => a.index === data.index && !("_id" in  a)) , 1)
        }
        // console.log(tasks)
        props.parentCallback(tasks)
      return Promise.resolve(task);
      }
      
    },
    delete: (data) => {
      // const task = tasks.find((t) => t._id === data._id);
      console.log("Deleting :",data);
      const filter = tasks.filter((obj)=> obj.index === data.index && "_id" in  obj)
      if(filter.length){
 const taskObj = tasks.find((obj) =>  obj.index === data.index && "_id" in  obj);
//  console.log(taskObj);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${props.Token}`
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDVlMDYwYzUxMmUyOGRiNGY5OGNjZjUiLCJpYXQiOjE2ODM4ODM1MzJ9.TukVcqn6hBJYPAX254oR4OmEUcIF-ox2Z0yvyyh9TtA"
      );

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${hostUrl}/api/v1/assets/category/${taskObj._id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      const notId = tasks.filter((obj)=> obj.index === data.index && !("_id" in  obj))
        if(notId.length){
          tasks.splice(tasks.findIndex(a => a.index === data.index && !("_id" in  a)) , 1)
        }

      const task = tasks.find(t => t.index === data.index);
      tasks = tasks.filter(t => t.index !== task.index);
      props.parentCallback(tasks)
      return Promise.resolve(task);
      }
     
    },
  };

  const styles = {
    container: { margin: "auto", width: "fit-content", height: "600px" },
  };

  return (
    <div style={styles.container}>
      <CRUDTable
        caption="Categories"
        fetchItems={(payload) => service.fetchItems(payload)}
      >
        <Fields>          
          <Field name="label" label="Category" placeholder="Category" />          
        </Fields>
        <CreateForm
          title="Category Creation"
          message="Create a new Category!"
          trigger="Create Category"
          onSubmit={(task) => service.create(task)}
          submitText="Create"
          validate={(values) => {
            const errors = {};

            if (!values.label) {
              errors.label = "Please, provide Category";
            }
            // if (tasks.map((a) => a.label).includes(values.label)) {
              if(tasks.findIndex(item => values.label.toUpperCase() === item.label.toUpperCase()) !== -1){
              errors.label = "Duplicate Category";
            }
            
            return errors;
          }}
        />

        <UpdateForm
          title="Category Update Process"
          message="Update Category"
          trigger="Update"
          onSubmit={(task) => service.update(task)}
          submitText="Update"
          validate={(values) => {
            const errors = {};
           
           
            console.log("update id", values , tasks );
            if (!values.label) {
              errors.label = "Please, provide Category";
            }
            if (
              tasks
                .filter((item) => item.index !== values.index && "index" in item)
                .findIndex(item => values.label.toUpperCase() === item.label.toUpperCase()) !== -1
            ) {
              errors.label = "Duplicate Category";
            }

            
            return errors;
          }}
        />

        <DeleteForm
          title="Category Delete Process"
          message="Are you sure you want to delete the Category?"
          trigger="Delete"
          onSubmit={(task) => service.delete(task)}
          submitText="Delete"
          validate={(values) => {
            console.log(values);
            const errors = {};
            if (!values.index) {
              errors.index = "Please, provide id";
            }
            return errors;
          }}
        />
      </CRUDTable>
    </div>
  );
}
