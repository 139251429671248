// export const config = {
//     appId : "13f3f2a3-9eec-4ec3-9320-b995f814e430",  //clientId
//     redirectUri:'http://localhost:3000', 
//     scopes:['user.read','Mail.Send'],    
//     authority:'https://login.microsoftonline.com/7580df91-f68e-4307-9bb0-02eb558039cc' // hostUrl/tenentId
// }


export const config = {
    appId : "52952379-7fe3-41eb-8b0f-3b56caeceac5",
    redirectUri: "https://assetportal.mgdevops.com/", //   
    scopes:['user.read','Mail.Send'],   
    authority:'https://login.microsoftonline.com/d1dcdee1-43d4-4d5c-a88d-6f228ec9a728'
}